:root {
  --white: #f5f5f5;
}

.App {
  text-align: center;
}

.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--white);
  z-index: -1;
}

.Login-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}
.Login {
  margin: auto;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 60%;
  display: flex;
  flex-direction: column;
}
.Login-form {
  width: 60%;
}
.Login-input {
  width: 100%;
  margin-bottom: 0.5rem;
}
.Login-button {
  margin-top: 0.5rem;
}
.Login-signup {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.Loading {
  width: 100vw;
  height: 100vh;
  display: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
}
.Loading-circle {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.Notecard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.Notecard-edit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}
.Notecard {
  margin-top: 9vh;
  max-width: 50vw;
  min-width: 40vw;
  padding: 2vh;
}
.Notecard-message {
  user-select: none;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Notecard-background {
  width: 100%;
  height: 100%;
  box-shadow: 5px 5px 15px #000000;
}
.Notecard-text {
  text-align: center;
  position: absolute;
  max-width: 30vw;
  min-width: 20vw;
  padding: 2vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}

.Background-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 98vw;
  min-height: 108vh;
  padding-bottom: 12vh;
  padding-top: 6vh;
}
.Background-image-container {
  width: 24vw;
  margin: 2vw;
  cursor: pointer;
  color: #7eaacd;
}
.Background-img {
  width: 100%;
  box-shadow: 4px 4px 10px #000000;
}
.Background-img-current {
  width: 100%;
}
.Background-current {
  margin-top: 2vw;
  border: 2px solid #dadada;
  border-radius: 7px;
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
  text-align: bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vh;
  margin-top: 2vw;
  padding: 1vw;
}
.Background-other {
  margin-top: 2vw;
  border: 2px solid var(--white);
  border-radius: 7px;
  outline: none;
  border-color: var(--white);
  box-shadow: 0 0 10px var(--white);
  text-align: bottom;
  margin-bottom: 2vh;
  padding: 1vw;
}
.Background-button-bar {
  display: grid;
  grid-template-columns: 35% 11% 8% 11% 35%;
  width: 100vw;
  position: fixed;
  bottom: 8vh;
}
.Background-cancel-button {
  grid-column-start: 2;
}
.Background-save-button {
  grid-column-start: 4;
}

.Button {
  margin-top: 2vh;
  margin-left: 1vh;
  margin-right: 1vh;
}

@media screen and (max-width: 767px) {
  .Notecard {
    max-height: 80vh;
    max-width: 80vw;
    padding: 2vh;
  }
  .Notecard-text {
    text-align: center;
    position: absolute;
    max-width: 70vw;
    min-width: 60vw;
    padding: 2vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
  }

  .Login {
    width: 95%;
  }
  .Login-form {
    width: 80%;
  }
}
